import { useRecoilState } from "recoil";
import {
  customJSONPrintManagerAtom,
  CustomJSONPrintManagerAtomProps,
} from "./CustomJSONPrintManagerAtoms";
import { useEffect, useState } from "react";
import { useApiGet, useFormRequest } from "../../hooks";
import { FormTypeEnum } from "../../dtos/form-type-enum";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../TrueUI/Dialogs/DialogConfirmation";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { CustomJSONResultDto } from "../../dtos/custom-json-result-dto";
import { isJSONParsable } from "../../utilities/objectFunctions";

/*
  To use the CustomJSONPrintManager component we just need to add the component where we need it
  then use the recoil set state hook and set the values for the request.
*/
const CustomJSONPrintManager = () => {
  const { sendMergeFormRequest } = useFormRequest();
  const [atomValue, setAtomValue] =
    useRecoilState<CustomJSONPrintManagerAtomProps | null>(
      customJSONPrintManagerAtom
    );
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const {
    responseGet: responseGetCustomJSON,
    dispatchGet: dispatchGetCustomJSON,
  } = useApiGet<CustomJSONResultDto>(
    `api/CustomJSON/GetCustomJSON?customJSONType=${atomValue?.customJSONType}` +
      `&sourceIDToBuildJSON_1=${
        atomValue?.sourceIDToBuildJSON_1
      }&sourceIDToBuildJSON_2=${atomValue?.sourceIDToBuildJSON_2 ?? 0}`
  );

  const getErrorMessage = (
    customJSONString: string,
    templateName: string[]
  ) => {
    if (templateName.length === 0 || templateName[0] === "")
      return "No print template found.";

    if (customJSONString === "")
      return "There is no valid JSON set up in the CustomJSON configurations.";

    return "";
  };

  const getTemplateName = (
    customJSONString: string,
    atomValue: CustomJSONPrintManagerAtomProps
  ) => {
    if (
      atomValue.configurationRequest.templateNameWithExtensionList !==
        undefined &&
      atomValue.configurationRequest.templateNameWithExtensionList.length > 0
    )
      return atomValue.configurationRequest.templateNameWithExtensionList[0];
    if (customJSONString !== "" && isJSONParsable(customJSONString)) {
      const customJSON = JSON.parse(customJSONString);

      return customJSON?.TemplateName !== undefined
        ? customJSON.TemplateName
        : [];
    }

    return [];
  };

  const print = (
    customJSONString: string,
    atomValue: CustomJSONPrintManagerAtomProps
  ) => {
    const templateName = getTemplateName(customJSONString, atomValue);

    if (
      customJSONString !== "" &&
      isJSONParsable(customJSONString) &&
      templateName.length > 0 &&
      templateName[0] !== ""
    ) {
      const customJSON = JSON.parse(customJSONString);
      sendMergeFormRequest({
        ...atomValue.configurationRequest,
        formType: FormTypeEnum.CUSTOM_FORM,
        customMergeFields: customJSON,
      });
    } else {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: getErrorMessage(customJSONString, templateName),
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }

    // We reset the atom value to null so we can restart the request process.
    setAtomValue(null);
  };

  useEffect(() => {
    if (atomValue !== null) {
      dispatchGetCustomJSON();
    }
  }, [atomValue]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetCustomJSON) && atomValue !== null) {
      print(
        responseGetCustomJSON.axiosResponse?.data.customJSON ?? "",
        atomValue
      );
    }
  }, [responseGetCustomJSON]);

  return (
    <DialogConfirmation
      id="id-custom-json-print-manager-dialog"
      {...dialogConfiguration}
      onCloseEvent={() => {
        setDialogConfiguration(null);
      }}
    />
  );
};

export default CustomJSONPrintManager;
