import { atom } from "recoil";
import { FormRequestDto } from "../../dtos/form-request-dto";

export type CustomJSONPrintManagerAtomProps = {
  customJSONType: number;
  sourceIDToBuildJSON_1: number;
  sourceIDToBuildJSON_2?: number;
  configurationRequest: Partial<FormRequestDto>;
};

const customJSONPrintManagerAtom = atom<CustomJSONPrintManagerAtomProps | null>(
  {
    key: "PrintManagerAtom",
    default: null,
  }
);

export { customJSONPrintManagerAtom };
