import { FC, useEffect } from "react";
import { useApiGet } from "../../../hooks";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { Button, Input } from "../../TrueUI";
import style from "./PayrollReport.module.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CustomJSONTypeEnum } from "../../../dtos/custom-json-type-enum";
import {
  dispatchPrintJSONAtom,
  refreshPayrollReportDetailsHeaderAtom,
} from "./PayrollReportAtoms";
import { PayrollReportDetailInfoPage } from "../../../dtos/payroll-report-detail-info-page";
import { customJSONPrintManagerAtom } from "../../CustomJSONPrint/CustomJSONPrintManagerAtoms";
import CustomJSONPrintManager from "../../CustomJSONPrint/CustomJSONPrintManager";

type PayrollReportHeaderProps = {
  payrollReportId?: string;
};

const PayrollReportHeader: FC<PayrollReportHeaderProps> = ({
  payrollReportId,
}) => {
  const [dispatchJSON, setDispatchJSON] = useRecoilState(dispatchPrintJSONAtom);
  const [refreshHeader, setRefreshHeader] = useRecoilState(
    refreshPayrollReportDetailsHeaderAtom
  );
  const setCustomJSONPrintManagerAtom = useSetRecoilState(
    customJSONPrintManagerAtom
  );

  const { responseGet, dispatchGet } = useApiGet<PayrollReportDetailInfoPage>(
    `api/PayrollReport/GetPayrollReportHeaderInfo?payrollReportId=${payrollReportId}`
  );

  const payrollReportInfo = responseGet?.responseData;

  const getCorrectFileName = () => {
    if (
      !isEmptyValue(payrollReportInfo?.insuredName) &&
      !isEmptyValue(payrollReportInfo?.policyNumber)
    ) {
      return `Payroll Report ${payrollReportInfo?.insuredName} ${payrollReportInfo?.policyNumber}`;
    }
    if (!isEmptyValue(payrollReportInfo?.insuredName)) {
      return `Payroll Report ${payrollReportInfo?.insuredName}`;
    }

    if (!isEmptyValue(payrollReportInfo?.policyNumber)) {
      return `Payroll Report ${payrollReportInfo?.policyNumber}`;
    }

    return `Payroll Report`;
  };

  const onClickPrint = () => {
    setCustomJSONPrintManagerAtom({
      customJSONType: CustomJSONTypeEnum.PAYROLL_REPORT,
      sourceIDToBuildJSON_1: parseInt(payrollReportId ?? "0"),
      configurationRequest: {
        templateNameWithExtensionList: [
          responseGet?.axiosResponse?.data?.configurationToPrint
            ?.templateName ?? "",
        ],
        customFileName: getCorrectFileName(),
      },
    });
  };

  useEffect(() => {
    if (dispatchJSON) {
      onClickPrint();
      setDispatchJSON(false);
    }
  }, [dispatchJSON]);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (refreshHeader) {
      dispatchGet();
      setRefreshHeader(false);
    }
  }, [refreshHeader]);

  return (
    <div className={style.payroll_report_header}>
      <Input
        id="policy-number"
        name="policy-number"
        readOnly
        label="Policy Number"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.policyNumber}
        inputWidth="auto"
      />
      <Input
        id="reported-from"
        name="reported-from"
        readOnly
        label="For PayrollReported From"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.reportFrom}
        inputWidth="auto"
      />

      <Input
        id="reported-to"
        name="reported-to"
        readOnly
        label="To"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.reportTo}
        inputWidth="auto"
      />

      <Input
        id="status"
        name="status"
        readOnly
        label="Status"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.status}
        inputWidth="300px"
      />
      <Button variantStyle="outlined" onClick={() => onClickPrint()}>
        PRINT FORM
      </Button>
      <CustomJSONPrintManager />
    </div>
  );
};
export default PayrollReportHeader;
